// /* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useRecommendsData from '../hooks/use-recommends';
import useRecommendsDataActivity from '../hooks/use-recommends-activity';
import { useSearch } from '../utils/common';
import BlockIconText from './BlockIconText';

function Recommends({ type }) {
  const search = useSearch();
  const data = type !== 'activity' ? useRecommendsData() : useRecommendsDataActivity();

  if (!data.length) {
    return null;
  }

  return (
    <div className="recommend">
      <div className="title">推荐阅读</div>
      <div className="recommentList">
        {data.map((node, index) => {
          return (
            <a href={`/blog/${node.slug}/${search}`} key={node.id}>
              <BlockIconText
                color={index % 2 ? 'green' : 'blue'}
                content={node.frontmatter.title}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
}

Recommends.propTypes = {
  type: PropTypes.string,
};

export default Recommends;
