/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Recommends from '../components/Recommends';
import FollowUsVideo from '../images/qrcode/official_blog.jpg';
import SubscribeForm from '../components/SubscribeForm';

const CenterH1 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{children}</h1>
    </div>
  );
};
const CenterH2 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{children}</h2>
    </div>
  );
};
const CenterH3 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{children}</h3>
    </div>
  );
};

const Center = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>{children}</p>
    </div>
  );
};

const CenterImage = ({ src, widthRatio = 100 }) => {
  return (
    <div style={{ width: `${widthRatio}%`, margin: '0 auto' }}>
      <img src={src} alt="pic" />
    </div>
  );
};

const CenterSizeImage = ({ src, width = 100 }) => {
  return (
    <div style={{ width: `${width}px`, margin: '0 auto' }}>
      <img src={src} alt="pic" />
    </div>
  );
};

const CenterImageTitle = ({ children }) => {
  return (
    <div style={{ textAlign: 'center', fontSize: 12 }}>
      <p style={{ color: '#bbb' }}>{children}</p>
    </div>
  );
};

const Foreword = ({ children }) => {
  return (
    <div style={{ borderLeft: '3px solid #ddd', padding: '12px 0 1px 15px', marginBottom: '1em' }}>
      <p style={{ color: '#bbb' }}>{children}</p>
    </div>
  );
};

const FontFamily = ({
  fontFamily = 'Optima-Regular, PingFangTC-light',
  fontSize = 20,
  fontWeight,
  children,
  color = '#262626',
}) => {
  return (
    <div style={{ fontFamily }}>
      {!fontWeight ? (
        <p style={{ fontSize, lineHeight: 1.3, marginBottom: 14, fontWeight: 700, color }}>
          {children}
        </p>
      ) : (
        <div style={{ fontSize, marginBottom: 14, color }}>{children}</div>
      )}
    </div>
  );
};

const LinkImage = ({ src, href }) => {
  return (
    <div>
      <a href={href} target="_blank" rel="noreferrer noopener">
        <img src={src} alt="pic" />
      </a>
    </div>
  );
};

const ColorSpan = ({ color = '#bbb', children }) => {
  return <span style={{ color }}>{children}</span>;
};

const BorderLine = ({ margin = 20 }) => {
  return <div style={{ margin: `${margin}px 0`, borderTop: '1px solid #ddd' }} />;
};

const shortcodes = {
  CenterH1,
  CenterH2,
  CenterH3,
  Center,
  CenterImage,
  CenterImageTitle,
  Foreword,
  FontFamily,
  LinkImage,
  CenterSizeImage,
  ColorSpan,
  BorderLine,
};

const PostLayout = ({ data: { mdx } }) => {
  const { frontmatter, body } = mdx;
  const isBlog = frontmatter.type === 'blog';
  return (
    <Layout
      current={isBlog ? 'blogs' : ''}
      subCurrent={isBlog ? 'blogs' : ''}
      customTitle={`${frontmatter.title}-Convertlab营销实验室`}
      customDescription={frontmatter.description}
      customKeywords={frontmatter.keywords}
    >
      <div className="postContainer">
        <div className="post">
          <MDXProvider components={shortcodes}>
            <h1>{frontmatter.title}</h1>
            <div className="authorContainer">
              <span className="name">{frontmatter.author}</span>
              <span className="date">{frontmatter.date}</span>
            </div>
            {!frontmatter.hideCover && (
              <img className="coverImage" alt="cover" src={frontmatter.cover} />
            )}
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
        <div className="sider">
          <div className="qrcode">
            <div id="_cl_qrcode_blog_article" style={{ width: 160 }}>
              <img src={FollowUsVideo} className="qrCode" alt="QR Code" style={{ width: 160 }} />
            </div>
            <div className="desc">关注获取更多营销咨询</div>
          </div>

          <div className="subscribe-form-container">
            <SubscribeForm theme="plain" />
          </div>
          <Recommends type={frontmatter.type} />
        </div>
      </div>
    </Layout>
  );
};
export default PostLayout;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        author
        date
        cover
        type
        description
        keywords
        hideCover
      }
    }
  }
`;
