import { useStaticQuery, graphql } from 'gatsby';

const useRecommendsDataActivity = () => {
  const { allMdx } = useStaticQuery(graphql`
    query MyQueryActityty {
      allMdx(
        filter: { frontmatter: { type: { eq: "activity" } } }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 5
      ) {
        nodes {
          frontmatter {
            title
          }
          slug
          id
        }
      }
    }
  `);
  return allMdx.nodes;
};

export default useRecommendsDataActivity;
